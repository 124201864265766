import React from 'react'
import Navbar from './components/Navbar'
import Home from './pages/Home'
import About from './pages/About'
import SolutionFind from './pages/SolutionFind'
import Contact from './components/Contact'
import Banner from './components/Banner'
import Footer from './components/Footer'
export default function App() {
  return (
    <div className='overflow-x-hidden'>
      <Navbar />
      <section id='home' className='mt-14'><Home/></section>
      <section id='banner' className='pt-10 pb-10'><Banner/></section>
      <section id='solutions' className='pt-4'><SolutionFind/></section>
      <section id='about' className='mt-4'><About/></section>
      <section id='contact' className='pt-4'><Contact/></section>
      <Footer/>
    </div>
  )
}
