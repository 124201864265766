import React from 'react'
import { motion } from 'framer-motion'
import { fadeIn } from '../variants'
import { TypeAnimation } from 'react-type-animation'
import Cards from '../components/Cards'

const SolutionFind = () => {
  const cardData = [
    {
      id: 1,
      title: 'Web Development',
      text: 'We specialize in developing high-quality, responsive websites using React.js, Tailwind CSS, and Django.',
      detailedText: 'Our web development services include creating high-quality, responsive websites using the latest technologies such as React.js, Tailwind CSS, and Django. We tailor our solutions to meet your business needs, ensuring a great user experience and seamless performance across all devices.',
      imageUrl: require('../assets/images/3.webp'),
    },
    {
      id: 2,
      title: 'Digital Marketing',
      text: 'Boost your online presence with our comprehensive digital marketing services.',
      detailedText: 'Our digital marketing services are designed to enhance your brand visibility and engagement. We create effective social media advertisements and marketing strategies to boost your online presence and drive traffic to your website.',
      imageUrl: require('../assets/images/1.webp'),
    },
    {
      id: 3,
      title: 'IoT Solutions for Agriculture',
      text: 'Innovate your agricultural practices with our IoT solutions for irrigation systems.',
      detailedText: 'Our IoT solutions for agriculture are specifically designed for irrigation systems. Our technology enhances existing irrigation setups, promoting efficient water usage without the need for system replacement, leading to better crop yield and resource management.',
      imageUrl: require('../assets/images/4.webp'),
    },
    {
      id: 4,
      title: 'Software Development',
      text: 'Our software development services include creating custom applications.',
      detailedText: 'We offer custom software development services tailored to your business requirements. Our solutions are high-quality, scalable, and secure, designed to support your operations and drive business growth.',
      imageUrl: require('../assets/images/2.webp'),
    },
    {
      id: 5,
      title: 'Mobile Development',
      text: 'Get robust and user-friendly mobile applications with our expertise in React Native.',
      detailedText: 'We specialize in creating robust and user-friendly mobile applications using React Native. Our apps provide seamless user experiences on both iOS and Android platforms, ensuring high performance and user satisfaction.',
      imageUrl: require('../assets/images/5.webp'),
    },
  ]

  return (
    <div className='w-full relative isolate overflow-hidden lg:overflow-visible bg-white min-h-[48vh] flex justify-center items-center pt-16 pb-16'>
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-80 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className='container mx-auto'>
        <motion.h1
          variants={fadeIn("left", 0.5)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.5 }} className='text-center text-4xl font-semibold mb-8 text-dark'>
          Let's Do
        </motion.h1>
        <motion.p
          variants={fadeIn("left", 0.6)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.6 }} className='text-center text-lg mb-4 mt-4'>
          <span>
            <TypeAnimation
              sequence={[
                "We specialize in creating high-quality, responsive websites and digital marketing solutions.", 500,
                "We specialize in creating high-quality, responsive websites and digital marketing solutions.", 500,
                "We specialize in creating high-quality, responsive websites and digital marketing solutions.", 500,
                "We specialize in creating high-quality, responsive websites and digital marketing solutions.", 500,
              ]}
              speed={70}
              className="text-blueGrey"
              wrapper="span"
              repeat={Infinity}
            />
          </span>
        </motion.p>
        <div className='flex flex-wrap items-center gap-4 justify-center'>
          {cardData.map((card) => (
            <Cards 
              key={card.id} 
              title={card.title} 
              text={card.text} 
              detailedText={card.detailedText} 
              imageUrl={card.imageUrl} 
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default SolutionFind
