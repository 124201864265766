import React, { useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import Logo from '../assets/logo/logo.png'
import { FaInstagram, FaGithub, FaWhatsapp, FaLinkedin } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
export default function Contact() {
    const [state, handleSubmit] = useForm("mvoeeroe");
    const [showModal, setShowModal] = useState(false);

    const closeModal = () => {
        setShowModal(false);
        // Reset form state

    };

    if (state.succeeded && !showModal) {
        setShowModal(true);
    }

    return (

        <div className='container mx-auto pt-10'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-16 gap-y-6 md:justify-between min-h-screen'>
                <motion.div variants={fadeIn("right", 0.3)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: false, amount: 0.3 }} className='flex-col justify-start md:justify-center'>
                    <img className='object-cover justify-center' src={Logo} alt='ttstack logo' />

                    <h3 className='text-3xl mt-5 text-center md:text-start font-bold tracking-tight text-gray-900'>
                        Let's Talk
                    </h3>
                    <p className='mt-3 text-xl leading-8 text-gray-700'>
                        We're here to help you with your software needs. Let's get started.
                    </p>
                    <ul className='list-none justify-center md:justify-start flex gap-x-4 mt-4 mb-6'>
                        <motion.li variants={fadeIn("left", 0.5)}
                            initial="hidden"
                            whileInView={"show"}
                            viewport={{ once: false, amount: 0.5 }}><a href='https://linkedin.com/in/tunahantatli' target='blank' className='text-xl hover:text-blue-600'><FaLinkedin /></a></motion.li>
                        <motion.li variants={fadeIn("left", 0.6)}
                            initial="hidden"
                            whileInView={"show"}
                            viewport={{ once: false, amount: 0.6 }}><a href='https://www.instagram.com/ttstack.dev' target='blank' className='text-xl hover:text-pink'><FaInstagram /></a></motion.li>
                        <motion.li variants={fadeIn("left", 0.7)}
                            initial="hidden"
                            whileInView={"show"}
                            viewport={{ once: false, amount: 0.7 }}><a href='https://github.com/tunahantatli' target='blank' className='text-xl hover:text-black'><FaGithub /></a></motion.li>
                        <motion.li variants={fadeIn("left", 0.8)}
                            initial="hidden"
                            whileInView={"show"}
                            viewport={{ once: false, amount: 0.8 }}><a href='https://api.whatsapp.com/send?phone=+905442555993' target='blank' className='text-xl hover:text-green-600'><FaWhatsapp /></a></motion.li>
                    </ul>
                </motion.div>
                <motion.div variants={fadeIn("left", 0.4)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: false, amount: 0.4 }}
                    className="mb-4"
                    >
                    <form
                        onSubmit={handleSubmit}
                        className='flex flex-col items-center border-[1px] p-6 space-y-4 w-full max-w-md rounded-lg'
                        action='https://formspree.io/f/mvoeeroe'
                    >
                        <h3 className='text-3xl text-indigo text-center font-bold'>Contact</h3>
                        <label className='text-center font-bold text-gray-700' htmlFor="email">
                            Email Address
                        </label>
                        <input
                            id="email"
                            type="email"
                            name="email"
                            className='border-[1px] rounded-lg w-full text-center'
                        />
                        <ValidationError
                            prefix="Email"
                            field="email"
                            errors={state.errors}
                        />
                        <label className='text-center font-bold text-gray-700' name="subject" htmlFor='subject'> Choose Subject</label>
                        <select
                            id="subject"
                            name="subject"
                            className='border-[1px] rounded-lg w-full text-center'
                        >
                            <option value="">Select subject</option>
                            <option value="website-development">Web Development</option>
                            <option value="digital-marketing">Digital Marketing</option>
                            <option value="iot-software">IOT For Agriculture</option>
                            <option value="mobil-development">Mobile Development</option>
                            <option value="other">Other Software Solutions</option>
                        </select>
                        <label className='font-bold text-gray-700' htmlFor="message">
                            Message
                        </label>
                        <textarea
                            id="message"
                            name="message"
                            className='border-2 rounded-lg w-full min-h-32 p-2'
                        />
                        <ValidationError
                            prefix="Message"
                            field="message"
                            errors={state.errors}
                        />
                        <button type="submit" disabled={state.submitting} className='px-4 py-2 bg-indigo text-white rounded-lg hover:bg-indigo/90'>
                            Submit
                        </button>
                    </form>
                    {showModal && (
                        <div className='fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center'>
                            <div className='bg-white justify-center items-center p-6 rounded-lg shadow-lg'>
                                <p>Thanks for messaging!</p>
                                <button
                                    onClick={closeModal}
                                    className='mt-4 px-4 py-2 items-center bg-blue-500 text-white rounded-lg hover:bg-blue-600'
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    )}
                </motion.div>
            </div>
        </div>
    );
}
