import React from 'react'

const Footer = () => {

  const date = new Date().getFullYear();
  return (
  <div className='w-full h-14 container bg-indigo flex justify-center items-center'>
    <p className='text-white text-sm'> © {date} ttstack.dev by Tunahan Tatlı. All rights reserved.</p>
  </div>
  )
}

export default Footer
