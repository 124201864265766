import React from 'react'
import { motion } from 'framer-motion'

const Modal = ({ showModal, setShowModal, title, detailedText, imageUrl }) => {
  if (!showModal) return null

  return (
    <div className="fixed container inset-0 bg-black bg-opacity-50 mb-4 mt-10 flex items-center justify-center z-50">
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        className="bg-white p-8 rounded-lg flex-col justify-center shadow-lg overscroll-y-auto w-[400px] max-w-full"
      >
        <h2 className="text-2xl font-semibold mb-4">{title}</h2>
       <div className='flex justify-center items-center w-full'>
       <img src={imageUrl} alt={title} className="w-40 h-48 object-cover mb-4 rounded-lg" />
       </div>
        <p className="mb-6">{detailedText}</p>
        <button
          onClick={() => setShowModal(false)}
          className="bg-indigo w-full text-center text-white py-2 px-4 rounded hover:bg-indigo/70"
        >
          Close
        </button>
      </motion.div>
    </div>
  )
}

export default Modal
