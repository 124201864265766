import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { fadeIn } from '../variants'
import Modal from './Modal'

const Cards = ({ title, text, detailedText, imageUrl }) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <motion.div
        variants={fadeIn("right", 0.5)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.5 }}
        className='mt-6 lg:w-96 h-[440px] shadow-lg shadow-purple/60 p-8 bg-white rounded-xl border-2'>
        <div className='h-[340px]'>
        <h3 className='text-center font-semibold text-3xl mb-2'>{title}</h3>
        <p className='text-center mb-4 mt-3'>{text}</p>
        </div>
        <div className='bottom-0'>
        <button
          onClick={() => setShowModal(true)}
          className='w-full border-indigo border-[1px] text-dark rounded-lg p-2 mb-4 text-center hover:bg-indigo hover:text-white'>
          Learn More
        </button>
        </div>
      </motion.div>
      <Modal showModal={showModal} setShowModal={setShowModal} title={title} detailedText={detailedText} imageUrl={imageUrl} />
    </>
  )
}

export default Cards
