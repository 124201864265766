import React from 'react'
import { motion } from 'framer-motion'
import { fadeIn } from '../variants'
import Video from '../assets/video/freecompress-About.mp4'
export default function About() {
    return (
        <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
            <div className="absolute inset-0 -z-10 overflow-hidden">
                <svg
                    className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                            width={200}
                            height={200}
                            x="50%"
                            y={-1}
                            patternUnits="userSpaceOnUse"
                        >
                            <path d="M100 200V.5M.5 .5H200" fill="none" />
                        </pattern>
                    </defs>
                    <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                        <path
                            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                            strokeWidth={0}
                        />
                    </svg>
                    <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
                </svg>
            </div>
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                        <motion.div variants={fadeIn("right", 0.5)}
                            initial="hidden"
                            whileInView={"show"}
                            viewport={{ once: false, amount: 0.5 }} className="lg:max-w-lg">
                            <h3 className="text-[54px] mb-4 font-bold tracking-tight uppercase text-gray-400 sm:text-4xl">
                                About Me
                            </h3>
                            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                Who am I?
                            </h1>
                            <p className="mt-6 text-xl leading-8 text-gray-700">
                                Hello! I am Tunahan Tatlı. I am a full stack developer who builds innovative and effective web applications for people and companies. I believe that technology, especially the web, can make everyone's life easier.
                            </p>
                        </motion.div>
                    </div>
                </div>
                <motion.div variants={fadeIn("left", 0.6)}
                    initial="visible"
                    whileInView={"show"}
                    viewport={{ once: false, amount: 0.6 }} className="-ml-12 rounded-lg -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                    <video 
                    autoPlay
                    muted

                    loop
                    className="w-full h-full rounded-lg bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                    >
                        <source src={Video} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </motion.div>
                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                        <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                            <p className="mb-4">
                                I believe that web technologies have the potential to make business processes more efficient and offer people new opportunities. I think everyone should be able to benefit from these technologies, so I prioritize accessibility and user-friendly designs in my work.
                            </p>
                            <p className="mb-4">
                                I have been doing full stack development since 2022. The education I received in the field of electronics and communications engineering reinforced my competence in adapting to different technologies and producing effective solutions in various projects. I develop projects in areas such as websites, Blockchain and IoT and always follow the latest technologies.
                            </p>
                            <p className="mb-4">
                                I am also the software development manager and co-founder of a startup called Eisytech. Here I develop Smart Agricultural Control Systems and we especially focus on the field of irrigation. Due to the climate crisis the world is facing, careful use of water is of great importance. Instead of traveling for kilometers and opening and closing valves for a simple operation such as irrigation, people can control their irrigation systems from their Android phones, thanks to the IoT system we developed. In addition, thanks to this system, it becomes possible to use other electrical devices in their garden via the application.
                            </p>
                            <p className="mb-4">
                                In accordance with engineering ethics, I take care to develop systems that are accessible, understandable and usable by everyone. I believe that technology should be democratized; That's why I always prioritize users' needs in my projects.
                            </p>
                            <p className="mb-4">
                                In my spare time, I am interested in activities such as fishing from the shore. This hobby allows me to stay connected to nature and maintain balance outside of work. I am also interested in activities such as learning new technologies, participating in community events, and writing technical blog posts. My goal is to enable more people to benefit from web technologies by sharing my knowledge and experiences.
                            </p>
                            <p className="mb-4">
                                If you want to know more about me, you can check out my <a href="https://tunahantatli.netlify.app" target="blank" className="text-indigo-800 font-bold">portfolio</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
