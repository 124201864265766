import React from 'react'
import { TypeAnimation } from 'react-type-animation'
export default function Banner() {
    return (
        <div className='w-full min-h-[78vh] flex items-center justify-center'>
            <div
                className="mb-6 text-center text-[36px] lg:text-[86px] font-secondary
            font-semibold text-deepPurple uppercase leading-[1]"
            >
                    <span>
                        <TypeAnimation
                            sequence={["responsive", 2000, "user-friendly", 2000, "creative", 2000]}
                            speed={50}
                            className="text-blueGrey banner-text"
                            wrapper="span"
                            repeat={Infinity}
                        />
                    </span>
            </div>
        </div>
    )
}
