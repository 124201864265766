import React from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';

export default function Home() {
    return (
        <div className='w-full min-h-[85vh]'>
            <div className='container mx-auto relative pt-10'>
                <div
                    className="absolute inset-x-0 -top-40 -z-20 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
                <div className="mx-auto max-w-2xl py-22 sm:py-28 lg:py-36 relative z-10">
                    <div className="hidden sm:mb-8 sm:flex sm:justify-center">
                        <motion.div
                            variants={fadeIn("left", 0.5)}
                            initial="hidden"
                            whileInView={"show"}
                            viewport={{ once: false, amount: 0.5 }}
                            className="relative rounded-full justify-center border-[1px] items-center text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20"
                        >
                            <span className='px-3 py-1'>Let's Work Together to Build Your Projects{' '}</span>
                            <a href="mailto:tunahantatl@gmail.com" className="font-semibold h-full px-3 py-1 text-white rounded-r-full bg-indigo hover:bg-indigo-500">
                                <span className="absolute inset-0" aria-hidden="true" />
                                Send Mail <span aria-hidden="true">&rarr;</span>
                            </a>
                        </motion.div>
                    </div>
                    <div className="text-center">
                        <motion.h1
                            variants={fadeIn("right", 0.5)}
                            initial="hidden"
                            whileInView={"show"}
                            viewport={{ once: false, amount: 0.5 }}
                            className="text-4xl font-bold capitalize tracking-tight text-gray-900 sm:text-6xl handy-text"
                        >
                            Designs the web solutions you dream of
                        </motion.h1>
                        <motion.p
                            variants={fadeIn("up", 0.5)}
                            initial="hidden"
                            whileInView={"show"}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mt-6 text-lg leading-8 text-gray-600"
                        >
                            Hello World! I am <strong>Tunahan Tatlı</strong>. I am a full stack developer who builds innovative and effective web applications for people and companies.
                        </motion.p>
                        <motion.div
                            variants={fadeIn("down", 0.6)}
                            initial="hidden"
                            whileInView={"show"}
                            viewport={{ once: false, amount: 0.6 }}
                            className="mt-10 flex items-center justify-center gap-x-6">
                            <a
                                href="#solutions"
                                className="rounded-md bg-indigo px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Get started
                            </a>
                            <a href="#about" className="text-sm font-semibold leading-6 text-gray-900">
                                Learn more <span>→</span>
                            </a>
                        </motion.div>
                    </div>
                </div>
                <div
                    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-20 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
